import React, { useState } from 'react';
import { Alert, lighten, darken } from '@mui/material';
import { COLOUR } from '../../../app-logic/constants/colour.const';

interface Props {
  show: boolean;
  message?: string;
}

export const NotificationBanner = ({ message, show }: Props) => {
  const [showNotification, setShowNotification] = useState(show);

  const handleClose = () => {
    setShowNotification(false);
  };

  return (
    <>
      {showNotification && (
        <Alert
          severity="info"
          onClose={handleClose}
          sx={{
            background: lighten(COLOUR.INFO, 0.8),
            border: `2px solid ${darken(COLOUR.INFO, 0.3)}`,
            color: darken(COLOUR.INFO, 0.8),
            maxWidth: '1200px',
            margin: '0 auto',
            marginBottom: '48px',
          }}
        >
          {message && <>{message}</>}
          {!message && (
            <>
              <p style={{ paddingBottom: '10px' }}>Upcoming scheduled maintenance:</p>
              <p style={{ paddingLeft: '30px' }}>
                Non-production environments: <b>TIME AND DATE.</b>{' '}
              </p>
              <p style={{ paddingLeft: '30px' }}>
                Production / Staging environments: <b>TIME AND DATE</b>{' '}
              </p>
              <p style={{ paddingTop: '10px' }}>Customers should expect service unavailability during these times.</p>
              <p style={{ paddingTop: '10px' }}>
                If you have any concerns or require further assistance, please feel free to{' '}
                <a href="https://adatree.atlassian.net/servicedesk/customer/portals" target="_blank" rel="noreferrer">
                  raise a ticket
                </a>
                .
              </p>
            </>
          )}
        </Alert>
      )}
    </>
  );
};
